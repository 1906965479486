import {
  Skeleton,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Container,
} from "@mui/material";
const ExamDetailsSkeleton = () => (
  <Box sx={{ my: 10 }}>
    <Container sx={{ marginTop: { xs: "125px", md: "110px" } }}>
      <Skeleton
        variant="rectangular"
        width="40%"
        height={50}
        sx={{ bgcolor: "#27b1bb", borderRadius: "10px" }}
      />
      {/* <Skeleton
        variant="rectangular"
        width="100%"
        height={100}
        sx={{ my: 2 }}
      /> */}
      <Skeleton
        variant="text"
        width="60%"
        height={50}
        sx={{ mx: "auto", my: 2 }}
      />
      {[...Array(10)].map((_, index) => (
        <Skeleton
          key={index}
          variant="text"
          width="95%"
          sx={{ mb: 1, mx: "auto" }}
        />
      ))}

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "10px",
          overflow: "hidden",
          border: "2px solid #27B1BB",
          position: "relative",
        }}
      >
        <Table>
          <TableBody>
            {[...Array(7)].map((_, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    width: "25%",
                    backgroundColor: "#27B1BB",
                    padding: "10px 16px",
                  }}
                >
                  <Skeleton variant="text" width="80%" height={24} />
                </TableCell>
                <TableCell sx={{ padding: "10px 16px" }}>
                  <Skeleton variant="text" width="90%" height={24} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <Skeleton
            variant="rectangular"
            width={200}
            height={50}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={200}
            height={50}
            sx={{ borderRadius: 1 }}
          />
        </Box>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {/* <Skeleton
          variant="rectangular"
          width={250}
          height={50}
          sx={{ borderRadius: 1, mr: 2 }}
        /> */}
        <Skeleton
          variant="rectangular"
          width={250}
          height={50}
          sx={{ borderRadius: 1 }}
        />
      </Box>

      <Skeleton
        variant="rectangular"
        width="100%"
        height={1200}
        sx={{ mt: 4 }}
      />
    </Container>
  </Box>
);

const TitleAndContentSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      sx={{ height: 100, width: "90%", margin: "1rem auto" }}
    />
    <Skeleton
      variant="text"
      sx={{ fontSize: "2rem", width: "80%", margin: "0 auto" }}
    />
    {[...Array(15)].map((_, index) => (
      <Skeleton key={index} variant="text" width="100%" sx={{ mb: 1 }} />
    ))}
  </>
);

export { ExamDetailsSkeleton, TitleAndContentSkeleton };
