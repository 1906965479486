import axios from "axios";
import { API_BASE_URL } from "../constants/constants";
const api = axios.create();
api.interceptors.request.use(async (config) => {
  config.baseURL = API_BASE_URL || "no/api";
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  config.headers["Content-Type"] = "application/json";
  return config;
});
export default api;
