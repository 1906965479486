import React from 'react'
import { Navigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
export default function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (!isAuthenticated  ) {
    // If user is not authenticated, redirect to the login page
    return <Navigate to='/' />;
  }
  // If user is authenticated, render the protected content
  return children;
}
export  function PrivateRouteCheckout({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  const isCartEmpty = localStorage.getItem("shoppingItems") !== null;
  const isCartItem = localStorage.getItem("shoppingItems")
  //  if (!isAuthenticated || isCartItem === "[]") {
  //   // If user is not authenticated, redirect to the login page
  //   return <Navigate to='/' />;
  // }
  // else
  //  if (!isAuthenticated && isCartItem === "[]") {
  //   // If user is not authenticated, redirect to the login page
  //   return <Navigate to='/' />;
  // }
 
  // else
//   if (isAuthenticated && !isCartEmpty ) {
//     // If user is not authenticated, redirect to the login page
//     return <Navigate to='/cart' />;
//   }
//   else if (isAuthenticated && isCartItem === "[]" ) {
//     // If user is not authenticated, redirect to the login page
//     return <Navigate to='/cart' />;
//   }
  return children;
 }

export  function PrivateRouteCart({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;

  // if (!isAuthenticated) {
  //   // If user is not authenticated, redirect to the login page
  //   return <Navigate to='/' />;
  // }
  // // If user is authenticated, render the protected content
  return children;
}



export function PrivateRoutePayment({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  const isCart = localStorage.getItem("shoppingItems") !== null;
  const isCartItem = localStorage.getItem("shoppingItems")
  // if (!isAuthenticated ) {
  //   // If user is not authenticated, redirect to the login page
  //   return <Navigate to='/' />;
  // }
  // if (!isCart || isCartItem === "[]") {
  //   return <Navigate to="/" />;
  // }


  // If user is authenticated, render the protected content
  return children;
}

export function PrivateAppbar({ children }) {
  const location = useLocation();
  const currentURL = location.pathname;
  if (!currentURL.includes('userdashboard')) {
    return children;
  }

  return null; // If path is /changepassword then don't render children
}