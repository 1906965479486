import React from "react";
import { Box, Container, Grid, Skeleton, Stack } from "@mui/material";

const PricingPageSkeleton = () => {
  const CardSkeleton = () => (
    <Box
      sx={{
        width: "80%",
        height: 550,
        p: 2,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        bgcolor: "#ebebeb",
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton variant="circular" width={60} height={60} />
        <Skeleton variant="text" width="40%" height={30} />
        <Skeleton variant="rectangular" width="80%" height={40} />
        <Skeleton variant="text" width="50%" height={80} />
        <Skeleton variant="rectangular" width="60%" height={40} />
        <Stack width="100%" spacing={1}>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={30} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Container sx={{ marginTop: { xs: "125px", md: "110px" } }}>
      {/* <Skeleton variant="text" width="100%" height={40} sx={{ my: 2 }} /> */}
      <Skeleton
        variant="rectangular"
        width="30%"
        height={50}
        sx={{ bgcolor: "#27b1bb", borderRadius: "10px" }}
      />
      <Skeleton
        variant="text"
        width="60%"
        height={60}
        sx={{ mb: 1, mx: "auto" }}
      />
      <Grid container spacing={1} sx={{ maxWidth: "1200px", mx: "auto" }}>
        {[...Array(3)].map((_, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardSkeleton />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingPageSkeleton;
