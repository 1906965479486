export const SplitDate = (arg) => {
  const date = new Date(arg);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return { day, month: monthNames[month], year };
};
