import { Typography, styled } from "@mui/material";

export const ShopingItem = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  position: "absolute",
  fontWeight: 500,
  fontSize: "0.75rem",
  minWidth: 15,
  lineHeight: 1,
  height: 15,
  borderRadius: 10,
  zIndex: 1,
  backgroundColor: "#27B1BB",
  color: "#fff",
  top: "14px",
  transform: "scale(1) translate(122%, -42%)",
}));
