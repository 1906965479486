import React from "react";
import { IconButton, Tooltip, Box } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { keyframes } from "@emotion/react";
import { useLocation } from "react-router-dom";

const animateBounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const ChatIconLink = () => {
  const chatLink = "https://vm.providesupport.com/0fkfyqrf4f56e1gjqie9whm0be";
  const location = useLocation();
  const shouldRenderChatIcon = !location.pathname.startsWith("/userdashboard");

  const openChatInNewWindow = (url) => {
    const width = 320;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
      url,
      "newwindow",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    return false;
  };
  if (!shouldRenderChatIcon) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        zIndex: 999,
        animation: `${animateBounce} 3s infinite`,
      }}
    >
      <Tooltip title="Live Chat">
        <IconButton
          aria-label="Live Chat"
          component="a"
          onClick={() => openChatInNewWindow(chatLink)}
          sx={{
            backgroundColor: "#27b1bb",
            color: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              color: "#27b1bb",
              backgroundColor: "#fff",
            },
            transition: "all 0.3s ease",
          }}
        >
          <ChatIcon sx={{ fontSize: { xs: 35, md: 45 } }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ChatIconLink;
