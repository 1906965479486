import React from "react";
import { Box, Stack, Skeleton, Divider, styled } from "@mui/material";

const RootStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: "507px",
  background: "white",
  padding: "20px",
  zIndex: "999",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "10px 1px",
  },
}));

const CartSkeletonLoading = () => {
  return (
    <RootStyle>
      <Stack spacing={3} pb={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="circular" width={24} height={24} />
        </Stack>
        <Divider />
        <Stack spacing={2} overflow="auto" height="65vh">
          {[...Array(3)].map((_, index) => (
            <React.Fragment key={index}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton variant="rectangular" width={145} height={120} />
                <Stack spacing={1} width="40%">
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Stack>
                <Stack spacing={1} alignItems="flex-end" width="20%">
                  <Skeleton variant="text" width={60} />
                  <Skeleton variant="rectangular" width={80} height={30} />
                </Stack>
              </Stack>
              <Divider />
            </React.Fragment>
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Skeleton variant="rectangular" width={100} height={40} />
          <Stack alignItems="flex-end" spacing={1}>
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={80} />
            <Skeleton variant="rectangular" width={180} height={40} />
          </Stack>
        </Stack>
      </Stack>
    </RootStyle>
  );
};

export default CartSkeletonLoading;
