import React, { useState, useEffect, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";

const bannerStyle = {
  position: "fixed",
  left: "0",
  width: "100%",
  backgroundColor: "#f9f9f9",
  padding: "5px",
  textAlign: "center",
  fontSize: "18px",
  color: "#27B1BB",
  zIndex: "998",
  boxShadow: "0 -4px 7px rgba(1, 0, 0, 5)",
};

const timerStyle = {
  fontWeight: "bold",
  color: "#ff0000",
};

const getExpirationTimestamp = () => {
  const storedTimestamp = localStorage.getItem("couponExpirationTimestamp");

  if (storedTimestamp) {
    return parseInt(storedTimestamp, 10);
  }

  const newExpirationTimestamp =
    new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
  localStorage.setItem(
    "couponExpirationTimestamp",
    newExpirationTimestamp.toString()
  );
  return newExpirationTimestamp;
};

const calculateTimeLeft = (expirationTimestamp) => {
  const difference = expirationTimestamp - new Date().getTime();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return { days: 0, hours: 0, minutes: 0, seconds: 0 };
};

const formatTimeLeft = (timeLeft) => {
  const { days, hours, minutes, seconds } = timeLeft;
  let result = "";

  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  result += `${minutes}m ${seconds}s`;

  return result;
};

const CouponDisplay = React.memo(({ coupon }) => {
  const [timeLeft, setTimeLeft] = useState(() =>
    calculateTimeLeft(getExpirationTimestamp())
  );

  const restartCountdown = useCallback(() => {
    const newExpirationTimestamp =
      new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
    localStorage.setItem(
      "couponExpirationTimestamp",
      newExpirationTimestamp.toString()
    );
    setTimeLeft(calculateTimeLeft(newExpirationTimestamp));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(getExpirationTimestamp());
      setTimeLeft(updatedTimeLeft);

      if (Object.values(updatedTimeLeft).every((value) => value === 0)) {
        restartCountdown();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [restartCountdown]);

  const formattedTimeLeft = useMemo(() => formatTimeLeft(timeLeft), [timeLeft]);

  if (!coupon) {
    return (
      <Box
        sx={{
          ...bannerStyle,
          height: { xs: "54px", sm: "44px", md: "32px" },
          zIndex: 999,
        }}
      />
    );
  }

  const { CouponCode, Description, DiscountValue } = coupon;

  return (
    <Box
      sx={{
        ...bannerStyle,
        height: { xs: "54px", sm: "44px", md: "32px" },
        zIndex: 999,
      }}
    >
      <span role="img" aria-label="fire">
        🔥
      </span>{" "}
      {Description} Coupon Code: "{CouponCode}" to get {DiscountValue}% off!{" "}
      <span role="img" aria-label="sun">
        🌞
      </span>{" "}
      Ends in <span style={timerStyle}>{formattedTimeLeft}</span>.
    </Box>
  );
});

export default CouponDisplay;
